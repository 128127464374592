<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board">
              <template v-slot:tab>
                <ul class="tab">
                  <li v-for="tabItem in tabs" class="tab-item" :key="tabItem.id">
                    <button class="tab-btn"
                      :class="{ 'is-active': tabActive === tabItem.value }"
                      type="button" @click="tabChange(tabItem.value)"
                    >
                      {{ tabItem.name }}
                    </button>
                  </li>
                </ul>
              </template>
              <template v-slot:body>
                <div class="slide-body">
                  <transition name="slide" mode="out-in">
                    <div v-if="isTemplatePage" class="tab-content">
                      <section class="section">
                        <div class="row">
                          <div class="col">
                            <Panel type="board" :padding="false">
                              <template v-slot:body>
                                <div class="scrollX">
                                  <TableWithCheckbox
                                    ref="templatesTable"
                                    :hasTableBorder="true"
                                    :isCheckAllShown="false"
                                    :storeModule="storeModule"
                                    :dataTable="dataTable"
                                    :labels="labels"
                                    @select-items="handleSelectedItems"
                                    :count="templatesCount"
                                  />
                                </div>
                              </template>
                              <template v-slot:footer>
                                <PaginationLog
                                  ref="templatesPagination"
                                  :modulePath="templatesModulePath"
                                  :subdomain="subdomain"
                                  :listLength="templatesCount"
                                  :searchConditions="searchConditions"
                                ></PaginationLog>
                              </template>
                            </Panel>
                          </div>
                        </div>
                      </section>
                    </div>
                  </transition>
                  <transition name="slide" mode="out-in">
                    <div v-if="isNewsPage" class="tab-content">
                      <section class="section">
                        <div class="row">
                          <div class="col">
                            <Panel type="board" :padding="false">
                              <template v-slot:body>
                                <div class="scrollX">
                                  <TableWithCheckbox
                                    :isCheckAllShown="false"
                                    ref="newsTable"
                                    :hasTableBorder="true"
                                    :storeModule="storeModule"
                                    :dataTable="dataTable"
                                    :labels="labels"
                                    @select-items="handleSelectedItems"
                                    :count="newsCount"
                                  />
                                </div>
                              </template>
                              <template v-slot:footer>
                                <PaginationLog
                                  ref="newsPagination"
                                  :modulePath="newsModulePath"
                                  :subdomain="subdomain"
                                  :listLength="newsCount"
                                  :searchConditions="searchConditions"
                                ></PaginationLog>
                              </template>
                            </Panel>
                          </div>
                        </div>
                      </section>
                    </div>
                  </transition>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <button :disabled="!isSelectedTemplatesExisted" class="btn btn-red" type="button" @click="showDeleteModal">削除</button>
          </li>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <ActionButton class="btn btn-main" :handle-submit="redirectToRegistPage" button-text="新規登録" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal" v-if="modal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">選択したテンプレートを削除しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-red" :handle-submit="deleteList" button-text="削除する" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//mixins
import nav from '@/mixins/nav/user';
import error from '@/mixins/plugin/error';
import modal from '@/mixins/plugin/modal';
//components
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import Modal from '@/components/Modal.vue';
//helpers
import { addThreeDotEndLine } from '@/helpers/formatData';

const templatePage = 1;
const newsPage = 2;

export default {
  name: 'EventUserTemplates',
  data: function() {
    return {
      pageName: '顧客管理',
      storeModule: "customer",
      tabActive: 1,
      templatePage,
      newsPage,
      tabs: [
        { value: 1, name: 'クーポン' },
        { value: 2, name: 'ニュース' }
      ],
      searchConditions: {},
      selectedTemplates: [],
      templatesModulePath: 'customer/getTemplatesList',
      newsModulePath: 'customer/getNewsList',
    };
  },
  mixins: [nav, error, modal],
  components: {
    TableWithCheckbox,
    PaginationLog,
    Modal,
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
      useTicketFlag: 'event/useTicketFlag',
      templatesList: 'customer/templatesList',
      newsList: 'customer/newsList',
      templatesCount: 'customer/templatesCount',
      newsCount: 'customer/newsCount',
    }),
    isTemplatePage() {
      return this.tabActive === this.templatePage;
    },
    isNewsPage() {
      return this.tabActive === this.newsPage;
    },
    subdomain() {
      return this.$route.params.subdomain;
    },
    labels() {
      return [
        { isCheckbox: true },
        { key: 'name', name: 'テンプレート名' },
        { key: 'title', name: 'タイトル' },
      ];
    },
    commonList() {
      return this.isTemplatePage ? this.templatesList : this.newsList;
    },
    dataTable() {
      return (this.commonList || []).map(item => {
        return {
          ...item,
          name: {
            content: item?.name,
            routeName: this.isTemplatePage ? 'EventUserTemplatesEdit' : 'EventUserTemplatesNewEdit',
            params: { id: item.id },
            isLink: true,
          },
          title: addThreeDotEndLine(item?.title),
        };
      });
    },
    isSelectedTemplatesExisted() {
      return this.selectedTemplates?.ids?.length > 0 || this.selectedTemplates?.allFlag;
    },
  },
  mounted() {
    const loading = this.$loading.show();
    const tabActive = sessionStorage.getItem('tabActive');
    if (tabActive) this.tabActive = Number(tabActive);
    Promise.all([
      this.$store.dispatch('event/getParentEventDetail', this.subdomain),
    ]).finally(() => {
      this.$loading.clear(loading);
    });
  },
  methods: {
    tabChange: async function(num) {
      this.tabActive = num;
      sessionStorage.setItem('tabActive', this.tabActive);
      this.resetPagination();
    },
    redirectToRegistPage() {
      sessionStorage.setItem('tabActive', this.tabActive);
      this.$router.push({ name: this.isTemplatePage ? 'EventUserTemplatesRegister' : 'EventUserTemplatesNewRegister' });
    },
    handleSelectedItems(items) {
      this.selectedTemplates = items;
    },
    showDeleteModal() {
      if (this.isSelectedTemplatesExisted) {
        this.showModal();
      } else {
        this.$message.showError('noSelectedTemplate');
      }
    },
    async deleteList() {
      const action = this.isTemplatePage ? 'customer/deleteTemplatesList' : 'customer/deleteNewsList';
      const result = await this.$store.dispatch(action, this.selectedTemplates);
      this.closeModal();
      this.resetTable();
      if (result) {
        this.$message.deleted('template');
        this.resetPagination();
      }
    },
    resetPagination() {
      if (this.isTemplatePage) {
        this.$refs.templatesPagination?.resetPagination();
      } else {
        this.$refs.newsPagination?.resetPagination();
      }
    },
    resetTable() {
      if (this.isTemplatePage) {
        this.$refs.templatesTable?.resetCheck();
      } else {
        this.$refs.newsTable?.resetCheck();
      }
    }
  },
  beforeDestroy() {
    if (!this.$route.name.includes('EventUserTemplates')) sessionStorage.removeItem('tabActive')
  }
};
</script>
